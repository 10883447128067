.scope-error-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 15vh;
}

.scrollable-footer-partial,
.e2956a7c5469f9dc-component {
    display: none !important;
}
